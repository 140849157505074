import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { Grid } from '@mui/material';

import { BookingLayout, DatePicker, Heading, HoursList } from 'components';
import { Day, Hour, MarketplaceScheduleType } from 'model';
import { AvailabilityHour } from 'model/Availability';
import {
    getNextAvailableDateThunk,
    getAvailabilityThunk
} from 'actions/availability/AvailabilityActions';
import { AvailabilityState, AvailabilityStatus } from 'reducers/availability/AvailabilityState';
import { CircularProgress, Container } from '@mui/material';
import { EditBooking } from 'model/Booking';
import { DateTime } from 'luxon';
import { Marketplace } from 'model';
import { useAuth, useMarketplace } from 'hooks';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingStep } from 'components/Booking/Booking';

interface BookingSelectDateProps {
    booking: EditBooking;
    marketplace: Marketplace;
    //
    onChange: (booking: EditBooking) => void;
    onContinue: () => void;
}

interface Schedule {
    sunday: Range;
    monday: Range;
    tuesday: Range;
    wednesday: Range;
    thursday: Range;
    friday: Range;
    saturday: Range;
}

interface Range {
    open: string;
    close: string;
}

interface VariableDuration {
    size_meta_variable: string;
    hours: number;
    minutes: number;
}

enum PopulatingStatus {
    Initial,
    Fetching,
    Success
}

export const BookingSelectDate: React.FunctionComponent<BookingSelectDateProps> = props => {
    const dispatch = useDispatch();

    const auth = useAuth();

    const marketplace = useMarketplace();
    const schedule = JSON.parse(marketplace.range.range);
    const availability = useSelector<RootState, AvailabilityState | undefined>(
        state => state.availability
    );
    const selectedPets = props.booking.petsToBooking;
    const durationObj: VariableDuration = { hours: 0, minutes: 0, size_meta_variable: '' };
    durationObj.size_meta_variable = selectedPets[0].pet.size;
    durationObj.hours =
        selectedPets[0].service!.durationHours != null ? selectedPets[0].service!.durationHours : 0;
    durationObj.minutes =
        selectedPets[0].service!.durationMinutes != null
            ? selectedPets[0].service!.durationMinutes
            : 0;
    const currentDate = DateTime.now().setZone(marketplace.timeZone);
    const [disabledButton, setDisabledButton] = useState<boolean>(true);
    const [populatingStatus, setPopulatingStatus] = useState<PopulatingStatus>(
        PopulatingStatus.Initial
    );
    const [hours, setHours] = useState<Hour[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedDate, setSelectedDate] = useState<Day>({
        object: currentDate,
        ISODate: currentDate.toISODate(),
        dayLabel: currentDate.toFormat('ccc', { locale: 'en' }),
        dayNumber: currentDate.toFormat('dd', { locale: 'en' })
    });

    const getHoursRange = (date: DateTime, schedule: Schedule) => {
        const range = [];

        let dateOpen = date;
        let dateClose = date;

        const selectedDateIsToday = date.hasSame(currentDate, 'day');

        // Day schedule
        const scheduleDay = getScheduleDay(dateOpen.weekday, schedule);

        // Special hour
        const specialHour = marketplace.specialHours.find(
            specialHour => specialHour.date === date.toFormat('yyyy-MM-dd')
        );

        // Open datetime
        let openHour = scheduleDay ? scheduleDay.open.split(':')[0] : '8';
        let openMinutes = scheduleDay ? scheduleDay.open.split(':')[1] : '0';

        // Close datetime
        let closeHour = scheduleDay ? scheduleDay.close.split(':')[0] : '5';
        let closeMinutes = scheduleDay ? scheduleDay.close.split(':')[1] : '0';

        if (specialHour && !specialHour.closed) {
            openHour = specialHour.businessHours.open.split(':')[0];
            openMinutes = specialHour.businessHours.open.split(':')[1];

            closeHour = specialHour.businessHours.close.split(':')[0];
            closeMinutes = specialHour.businessHours.close.split(':')[1];
        }

        dateOpen = dateOpen.set({ hour: parseInt(openHour), minute: parseInt(openMinutes) });
        dateClose = dateClose.set({ hour: parseInt(closeHour), minute: parseInt(closeMinutes) });

        let blockDurationInMinutes = 30;

        if (marketplace.scheduleType === MarketplaceScheduleType.MultiSlots) {
            blockDurationInMinutes = 15;
        }

        for (
            let i = dateOpen.toMillis();
            i < dateClose.toMillis();
            i += blockDurationInMinutes * 60 * 1000
        ) {
            // const date = moment(new Date(i), 'hh:mm A');
            const date = DateTime.fromJSDate(new Date(i)).setZone(marketplace.timeZone);

            if (selectedDateIsToday && date < currentDate) {
                continue;
            }

            range.push(date.toFormat('hh:mm a'));
        }

        return range;
    };

    const getScheduleDay = (day: number, schedule: Schedule) => {
        switch (day) {
            case 1:
                return schedule.monday;
            case 2:
                return schedule.tuesday;
            case 3:
                return schedule.wednesday;
            case 4:
                return schedule.thursday;
            case 5:
                return schedule.friday;
            case 6:
                return schedule.saturday;
            case 7:
                return schedule.sunday;
        }
    };

    const fetchAvailability = async (
        date: Day['ISODate'],
        petId: string,
        petSize: string,
        hours: string
    ) => {
        if (availability?.loading) {
            return;
        }

        await dispatch(getAvailabilityThunk(date, petId, petSize, hours));
    };

    useEffect(() => {
        if (availability?.status === AvailabilityStatus.FetchSuccess) {
            const staffId = props.booking.staffId;
            const { date, availableHours } = availability.availableHours[0];

            if (auth.customer?.blocked || props.booking.customer.blocked) {
                setHours([]);
                setLoading(false);
                return;
            }

            // Prevent updating available hours when date it's not the same as selected
            if (date != selectedDate.ISODate || populatingStatus === PopulatingStatus.Fetching) {
                return;
            }

            let hours: Array<Hour> = availableHours.map((hours: AvailabilityHour) => ({
                hour: hours.time,
                cycle: hours.time.toLowerCase().slice(-2),
                staff: hours.staffAvailable
            }));

            if (staffId) {
                hours = hours.filter(hour => hour.staff.includes(staffId));
            }

            if (hours.length === 0 && populatingStatus === PopulatingStatus.Initial) {
                setLoading(true);
                setPopulatingStatus(PopulatingStatus.Fetching);
                dispatch(
                    getNextAvailableDateThunk(
                        selectedDate.ISODate,
                        selectedPets[0].service!.id.toString(),
                        selectedPets[0].pet.size,
                        props.booking.staffId
                    )
                );

                return;
            }

            setHours(hours);
            setLoading(false);
            setPopulatingStatus(PopulatingStatus.Success);
        }

        if (availability?.status === AvailabilityStatus.GetNextDateSuccess) {
            const { date } = availability.availableHours[0];

            const nextDate = DateTime.fromISO(date, {
                zone: marketplace.timeZone
            });

            setLoading(false);
            setPopulatingStatus(PopulatingStatus.Success);

            if (availability.availableHours.some(item => item.availableHours.length > 0)) {
                onDateChange(
                    {
                        object: nextDate,
                        ISODate: nextDate.toISODate(),
                        dayLabel: nextDate.toFormat('ccc', { locale: 'en' }),
                        dayNumber: nextDate.toFormat('dd', { locale: 'en' })
                    },
                    true
                );
            } else {
                setHours([]);
            }
        }
    }, [availability]);

    const onDateChange = (date?: Day, preventFetching = false) => {
        if (!date) {
            setDisabledButton(true);
            return;
        }

        // New date is equal to selected date
        if (!preventFetching && date.object.equals(selectedDate.object)) {
            setDisabledButton(true);
            setLoading(true);

            fetchAvailability(
                selectedDate.ISODate,
                selectedPets[0].service!.id.toString(),
                selectedPets[0].pet.size,
                getHoursRange(date.object, schedule).join(',')
            );
            return;
        }

        date.object = date.object.setZone(marketplace.timeZone);

        setDisabledButton(true);
        setSelectedDate(date);
        setLoading(true);

        selectedPets &&
            selectedPets.forEach((item, key: number) => {
                if (key === undefined) return;

                const newPet = selectedPets[key];
                newPet.hour = undefined;

                return;
            });

        const booking = { ...props.booking, date };
        props.onChange(booking);
    };

    const onSelectedHour = (hour: Hour, index: number) => {
        if (!selectedPets) return;

        const petWithHour = selectedPets[index];
        petWithHour.hour = hour;

        setDisabledButton(false);

        const booking = { ...props.booking, date: selectedDate, petWithHour };
        props.onChange(booking);
    };

    const isFormInvalid = useMemo(() => {
        return props.booking.petsToBooking.every(item => item.hour !== undefined);
    }, [props.booking]);

    const spinnerStyles = {
        container: {
            marginTop: 16,
            display: 'flex',
            justifyContent: 'center'
        },
        spinner: { color: '#eab464' }
    };

    return (
        <BookingLayout
            showFooter={{
                disabled: !isFormInvalid,
                onClick: props.onContinue
            }}
            showResume={props.booking}
            leftColumn={
                <Grid item xs={12}>
                    <Heading title="Select date & time" boxProps={{ sx: { mb: 2 } }} />
                    <DatePicker
                        value={selectedDate}
                        loading={loading}
                        onChange={onDateChange}
                        disabled={[]}
                    />

                    <FormErrorList step={BookingStep.DateSelect} />

                    {loading && (
                        <Container
                            className="BookingLayout-container"
                            style={spinnerStyles.container}
                        >
                            <CircularProgress
                                size={75}
                                thickness={4}
                                style={spinnerStyles.spinner}
                            />
                        </Container>
                    )}

                    {hours &&
                        !loading &&
                        !availability?.loading &&
                        selectedPets?.map((item, key) => (
                            <HoursList
                                key={key}
                                index={key}
                                hours={hours}
                                {...(item.hour && { selected: item.hour })}
                                petName={item.pet.name}
                                //
                                onChange={onSelectedHour}
                            />
                        ))}
                </Grid>
            }
        />
    );
};

export default BookingSelectDate;
